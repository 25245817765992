<template>

  <div class="card-body row">

    <div class="col-sm-2">
      <label> {{ sortbyTxt }} </label>
      <el-select v-model="sortby.column" :placeholder="sortbyTxt"
        v-on:change="sortByCol">
        <el-option class="select-default"
          v-for="item in sortby.columns"
          :key="item.prop" :label="item.label" :value="item.prop">
        </el-option>
      </el-select>
    </div>

    <div class="col-sm-2">
      <label> {{ perpageTxt }} </label>
      <el-select  v-model="pagination.perPage" :placeholder="perpageTxt">
        <el-option class="select-default"
          v-for="item in pagination.perPageOptions"
          :key="item" :label="item" :value="item">
        </el-option>
      </el-select>
    </div>

    <div class="col-sm-2">
      <label> {{ sortbyOrder }} </label>
      <el-select v-model="sortby.order" :placeholder="sortbyOrder"
        v-on:change="sortByCol">
        <el-option class="select-default"
          v-for="item in sortby.orders"
          :key="item.prop" :label="item.label" :value="item.prop">
        </el-option>
      </el-select>
    </div>


    <div class="col-sm-6">
      <div class="pull-right">
        <label> {{ searchTxt }}</label>
        <fg-input class="input-sm"
                  v-model="searchQuery"
                  addon-right-icon="nc-icon nc-zoom-split">
        </fg-input>
      </div>
    </div>

    <div class="col-sm-12 mt-2">
      <el-table class="table-striped" :data="queriedData" border style="width: 100%">

        <el-table-column :label="`${$t('action')}`" min-width="100" width="100">
          <span slot-scope="scope" v-if="adminusr">
            <div class="btn-group">
              <el-tooltip :content="`${$t('approve_operation')}`" placement="top">
                <p-button type="primary" round icon v-on:click="approveWith(`${scope.row.tran_id}`)" v-if="scope.row.approval_state === 'pending'">
                  <i class="fa fa-check"></i>
                </p-button>
              </el-tooltip>
              <el-tooltip :content="`${$t('reject_operation')}`" placement="top">
                <p-button type="danger" round icon v-on:click="cancelWith(`${scope.row.tran_id}`)" v-if="scope.row.approval_state === 'pending'">
                  <i class="fa fa-ban"></i>
                </p-button>
              </el-tooltip>
              <el-tooltip :content="`${$t('undo_reject')}`" placement="top">
                <p-button type="warning" round icon v-on:click="undoCancelWith(`${scope.row.tran_id}`)" v-if="scope.row.approval_state === 'rejected'">
                  <i class="fa fa-recycle"></i>
                </p-button>
              </el-tooltip>
              <el-tooltip :content="`${$t('undo_approve')}`" placement="top">
                <p-button type="danger" round icon v-on:click="undoApproveWith(`${scope.row.tran_id}`)" v-if="scope.row.approval_state === 'approved'">
                  <i class="fa fa-recycle"></i>
                </p-button>
              </el-tooltip>
              <el-tooltip :content="`${$t('show_description')}`" placement="top">
                <p-button type="info" round icon v-on:click="showDescription(`${scope.row.description}`)">
                  <i class="fa fa-eye"></i>
                </p-button>
              </el-tooltip>
            </div>
          </span>

          <span slot-scope="scope" v-else>
            <p-button type="danger" round v-on:click="cancelWith(`${scope.row.tran_id}`)" v-if="scope.row.approval_state === 'pending'">
              <i class="fa fa-ban"></i> {{ scope.row.tran_id }}
            </p-button>
            <span v-else>
              {{ scope.row.tran_id }}
            </span>
          </span>
        </el-table-column>

        <el-table-column v-for="column in tableColumns"
                         :key="column.label"
                         :min-width="column.minWidth"
                         :prop="column.prop"
                         :label="column.label">
        </el-table-column>

      </el-table>
    </div>
    <div class="col-sm-6 pagination-info">
      <p class="category">{{ $t('showingtxt') }} {{from + 1}} {{ $t('totxt') }} {{to}} {{ $t('oftxt') }} {{total}} {{ $t('entriestxt') }}</p>
    </div>
    <div class="col-sm-6">
      <p-pagination class="pull-right"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total="pagination.total">
      </p-pagination>
    </div>
  </div>

</template>

<script>
  import { Button, Card } from 'src/components/UIComponents'
  import {Tooltip, Table, TableColumn, Select, Option} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'

  export default {
    components: {
      Card, [Button.name]: Button, PPagination,
      [Tooltip.name]: Tooltip, [Select.name]: Select, [Option.name]: Option,
      [Table.name]: Table, [TableColumn.name]: TableColumn
    },
    name: 'table-withdrawal',
    props: [
      'tableData',
      'sortby',
      'pagination',
      'propsToSearch',
      'tableColumns',
      'clonedTableData',
      'adminusr'
    ],
    data() {
      return {
        perpageTxt: this.$t('perpage'),
        sortbyTxt: this.$t('sortby'),
        sortbyOrder: this.$t('sortbyorder'),
        searchTxt: this.$t('searchtxt'),
        searchQuery: '',
      }
    },
    computed: {
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      queriedData () {
        if (!this.searchQuery) {
          this.pagination.total = this.tableData.length
          return this.pagedData
        }
        let result = this.tableData
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch) {
              //let rowValue = row[key].toString()
              let searchRegex = new RegExp(this.searchQuery, 'gi');
              //if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
              if (row[key].toString().match(searchRegex) !== null) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination.total = result.length
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      }
    },
    methods: {
      approveWith(tranid) {
        this.$emit('on-approve-with', tranid, false);
      },
      undoApproveWith(tranid) {
        this.$emit('on-approve-with', tranid, true);
      },
      cancelWith(tranid) {
        this.$emit('on-cancel-with', tranid, false);
      },
      undoCancelWith(tranid) {
        this.$emit('on-cancel-with', tranid, true);
      },
      showDescription(desc) {
        this.$emit('on-show-desc', desc);
      },
      sortItNum (a, b) {
        if (this.sortby.order == 'asc') {
          return a[this.colSort] - b[this.colSort];
        }
        return b[this.colSort] - a[this.colSort];
      },
      sortIt (a, b) {
        if (this.sortby.order == 'asc') {
          if (a[this.colSort] < b[this.colSort])
            return -1;
          if (a[this.colSort] > b[this.colSort])
            return 1;
        }
        if (b[this.colSort] < a[this.colSort])
          return -1;
        if (b[this.colSort] > a[this.colSort])
          return 1;
        return 0;
      },
      sortByCol(col) {
        this.colSort = this.sortby.column;
        if (this.colSort == 'tran_id' || this.colSort == 'request_dt' || this.colSort == 'currency' || this.colSort == 'payment_method' || this.colSort == 'decision_dt' || this.colSort == 'approval_state') {
          this.tableData.sort(this.sortIt);
        }
        else {
          this.tableData.sort(this.sortItNum);
        }
      }
    },
    mounted() {
      this.sortByCol(this.sortby.column);
    }
  }
</script>
<style lang="scss">
  .adjust-width {
    width: 325px;
  }
</style>
