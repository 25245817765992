<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-2">
          <div class="form-group">
            <label>{{sortbyTxt}}</label>
            <el-select  v-model="sortby.column" :placeholder="sortbyTxt"
              v-on:change="sortByCol">
              <el-option class="select-default"
                v-for="item in sortby.columns"
                :key="item.prop" :label="item.label" :value="item.prop">
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group">
            <label>{{perpageTxt}}</label>
            <el-select
              v-model="pagination.perPage" :placeholder="perpageTxt">
              <el-option class="select-default"
                v-for="item in pagination.perPageOptions"
                :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="col-md-3">
          <div class="form-group">
              <label>{{searchTxt}} </label>
              <fg-input class="input-sm"
                        :placeholder="searchTxt"
                        v-model="searchQuery"
                        addon-right-icon="nc-icon nc-zoom-split">
              </fg-input>
          </div>
        </div>


        <!-- <div class="col-md-2">
          <label> &nbsp </label>
          <div class="col-md-3" v-if="false">
            <el-tooltip :content="`${$t('cannotselltomarket')}`" :open-delay="100" placement="top" popper-class="adjust-width" v-if="myownentry || parseFloat(walletbal) < 500">
              <p-button class="move-up-abit" type="success" style="opacity: 0.5;">
                <i class="fa fa-plus"></i> {{ $t('becomeseller') }}
              </p-button>
            </el-tooltip>

            <p-button class="move-up-abit" type="success" v-on:click="openModal()" v-else>
              <i class="fa fa-plus"></i> {{ $t('becomeseller') }}
            </p-button>
          </div>

          <div class="col-md-3 text-right" v-else>
            <p-button class="move-up-abit text-right" type="info" v-on:click="openModal()" :disabled="myownentry">
              <i class="fa fa-plus"></i> {{ $t('becomebuyer') }}
            </p-button>
          </div>
        </div> -->

      </div>
    </div>
    <div class="col-sm-12 mt-2">
      <el-table class="table-striped" :data="queriedData" border style="width: 100%">

        <el-table-column :label="namecol" min-width="100">
          <span slot-scope="scope">
            <div class="btn-group" v-if="(scope.row.mine && !scope.row.add) || adminusr">
              <p-button type="danger" link v-on:click="deleteEntry(`${scope.row.userid}`, `${scope.row.type}`)">
                <i class="fa fa-ban" style="font-size: x-large;"></i>
              </p-button>
              <p-button type="" link v-on:click="openModalWith(`${scope.row.userid}`)">
                {{ scope.row.first_name }}  {{ scope.row.last_name }}
              </p-button>
            </div>

            <span v-else>
              {{ scope.row.first_name }}  {{ scope.row.last_name }}
            </span>
          </span>
        </el-table-column>
        <el-table-column v-for="column in tableColumns"
                         :key="column.label"
                         :min-width="column.minWidth"
                         :prop="column.prop"
                         :label="column.label">
        </el-table-column>

      </el-table>
    </div>
    <div class="col-sm-6 pagination-info">
      <p class="category">{{ $t('showingtxt') }} {{from + 1}} {{ $t('totxt') }} {{to}} {{ $t('oftxt') }} {{total}} {{ $t('entriestxt') }}</p>
    </div>
    <div class="col-sm-6">
      <p-pagination class="pull-right"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total="pagination.total">
      </p-pagination>
    </div>
  </div>

</template>

<script>
  import { Button, Card } from 'src/components/UIComponents'
  import {Tooltip, Table, TableColumn, Select, Option} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'

  export default {
    components: {
      Card, [Button.name]: Button, PPagination,
      [Tooltip.name]: Tooltip, [Select.name]: Select, [Option.name]: Option,
      [Table.name]: Table, [TableColumn.name]: TableColumn
    },
    name: 'table-fund',
    props: [
      'tableData', 'sortby', 'pagination', 'propsToSearch', 'tableColumns',
      'clonedTableData', 'myownentry', 'adminusr', 'walletbal', 'seller'
    ],
    data() {
      return {
        perpageTxt: this.$t('perpage'), sortbyTxt: this.$t('sortby'), namecol: this.$t('nametxt'),
        searchQuery: '', searchTxt: this.$t('searchtxt')
      }
    },
    computed: {
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      queriedData () {
        if (!this.searchQuery) {
          this.pagination.total = this.tableData.length
          return this.pagedData
        }
        let result = this.tableData
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch) {
              //let rowValue = row[key].toString()
              let searchRegex = new RegExp(this.searchQuery, 'gi');
              //if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
              if (row[key].toString().match(searchRegex) !== null) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination.total = result.length
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      }
    },
    methods: {
      openModal(uid) {
        this.$emit('on-open-with', uid);
      },
      deleteEntry(uid, type) {
        this.$emit('on-delete-entry', uid, type);
      },
      sortItNum (a, b) {
        return a[this.colSort] - b[this.colSort];
      },
      sortIt (a, b) {
        if (a[this.colSort] < b[this.colSort])
          return -1;
        if (a[this.colSort] > b[this.colSort])
          return 1;
        return 0;
      },
      sortByCol(col) {
        this.colSort = col;
        if (col == 'fullname' || col == 'phone' || col == 'userid') {
          this.tableData.sort(this.sortIt);
        }
        else {
          this.tableData.sort(this.sortItNum);
        }
      }
    }
  }
</script>
<style lang="scss">
  .adjust-width {
    width: 325px;
  }
</style>
